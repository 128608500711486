import React, { useState, useEffect } from "react";
import "../styles/addBanner.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import "../styles/addBill.css";
import { useNavigate, useParams } from "react-router-dom";
import { Button, message, DatePicker, Select } from "antd";
import { checkAccess } from "./AuthorizationService";
import dayjs from "dayjs";
import * as queries from "../graphql/queries";
const { Option } = Select;

function CreditReceipt(props) {
  const navigate = useNavigate();
  const [updatedBy, setupdatedBy] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [installmentAmount, setInstallmentAmount] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [date, setDate] = useState(dayjs(new Date()));
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [bankBlnc, setBankBlnc] = useState({});

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemById(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("BankPayments")) navigate("/homePage");
  }, []);

  const getItemById = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setBankBlnc(res.bankBlnc ? JSON.parse(res.bankBlnc) : {});
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (installmentAmount == "")
      return alert("Please enter installment amount");
    if (source == "") return alert("Please select source bank name");
    if (destination == "") return alert("Please select destination bank name");
    if (source == destination)
      return alert("Source and Destination bank cannot be same");

    setSpinnerState(true);

    let balance = 0;
    let tempBlnc = {};
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res = allTodos.data.getItemById;
      tempBlnc = JSON.parse(res.bankBlnc);
      balance = tempBlnc[source] ? tempBlnc[source].currentBalance : 0;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    try {
      let update = await API.graphql({
        query: mutations.addItem,
        variables: {
          updatedBy: updatedBy,
          installmentAmount: installmentAmount,
          shopId: shopId,
          outletShopId: outletShopId,
          date: date.format("YYYY-MM-DD"),
          source: source,
          destination: destination,
          type: "BankPayments",
          balance: balance - installmentAmount,
          bankName: source,
          location: destination,
        },
      });
      duplicate(tempBlnc);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const duplicate = async (tempBlnc) => {
    let balance = 0;
    balance = tempBlnc[destination] ? tempBlnc[destination].currentBalance : 0;

    try {
      let update = await API.graphql({
        query: mutations.addItem,
        variables: {
          updatedBy: updatedBy,
          installmentAmount: installmentAmount,
          shopId: shopId,
          outletShopId: outletShopId,
          date: date.format("YYYY-MM-DD"),
          source: source,
          destination: destination,
          type: "BankPaymentsDup",
          balance: balance + installmentAmount,
          bankName: destination,
          location: source,
        },
      });
      reduceBalance();
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const reduceBalance = async () => {
    console.log("reduce");
    try {
      await API.graphql({
        query: mutations.updateBankBlnc,
        variables: {
          shopId: shopId,
          bankName: source,
          installmentAmount: installmentAmount,
          from: "reducePayment",
          updatedBy: updatedBy,
        },
      });
      updateBalance();
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const updateBalance = async () => {
    console.log("add");
    try {
      await API.graphql({
        query: mutations.updateBankBlnc,
        variables: {
          shopId: shopId,
          bankName: destination,
          installmentAmount: installmentAmount,
          from: "creditPayment",
          updatedBy: updatedBy,
        },
      });
      message.error("Bank Transfer Done");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <div style={{ fontWeight: "bold" }}>
          <h2>Bank Payment:</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="addProductFormContainer">
            <div className="fieldStyle">
              <span className="fieldName">Installment Amount</span>
              <input
                className="addProdInputStyle"
                value={installmentAmount}
                onChange={(e) => setInstallmentAmount(e.target.value)}
                maxLength={10}
              />
            </div>
            <div className="fieldStyle">
              <span className="fieldName">Date</span>
              <DatePicker
                onChange={(date) => setDate(date)}
                value={date}
                className="addProdInputStyle"
              />
            </div>
            <div className="fieldStyle">
              <span className="fieldName">Source Bank Name</span>
              <Select
                className="addProdInputStyle"
                value={source}
                onChange={(value) => {
                  setSource(value);
                }}
              >
                <Option value="">Select a bank</Option>
                {props.common.bankList.map((item) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </div>
            <div className="fieldStyle">
              <span className="fieldName">Destination Bank Name</span>
              <Select
                className="addProdInputStyle"
                value={destination}
                onChange={(value) => {
                  setDestination(value);
                }}
              >
                <Option value="">Select a bank</Option>
                {props.common.bankList.map((item) => {
                  return <Option value={item}>{item}</Option>;
                })}
              </Select>
            </div>
            <div style={{ display: "flex" }}>
              <Button
                className="button"
                type="primary"
                disabled={spinnerState}
                style={{ margin: 10, width: 200 }}
                onClick={() => checkInput()}
              >
                Update Bank Balance
              </Button>
            </div>
          </div>
          <div>
            {Object.entries(bankBlnc).map(([key, value]) => {
              return (
                <div style={{ display: "flex" }}>
                  <p style={{ width: 200 }}> {value.bankName}</p>
                  <p style={{ width: 200 }}>
                    {value.currentBalance.toFixed(2)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(CreditReceipt);
