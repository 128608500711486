import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, message } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { getItemsByOutletPagination } from "./functionCall";
import { CSVLink } from "react-csv";

function ListComponents(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [outletShopId, setOutletShopId] = useState("");
  const [term, setTerm] = useState("");
  const test = useParams();

  const headers = [
    { label: "Id", key: "randomId" },
    { label: "Name", key: "shopName" },
    { label: "Contact", key: "name" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Category", key: "category" },
  ];

  const Columns = [
    {
      title: "Id",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "shopName",
      key: "shopName",
      ellipsis: true,
    },
    {
      title: "Contact Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      ellipsis: true,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: true,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      ellipsis: true,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddVendor/${id}/${test.test}`)}>View</a>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => deleteItem(id)} style={{ color: "red" }}>
          Delete
        </a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByOutlet(user.attributes["custom:outletShopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (props.common.shopType === "root") navigate("/homePage");
  }, []);

  const getItemsByOutlet = async (outletShopId) => {
    let allItems = await getItemsByOutletPagination(
      test.test == "vendor" ? "Vendor" : "Dealer",
      "live",
      outletShopId
    );

    setAllItems(
      allItems.sort(function (a, b) {
        if (a.shopName.toLowerCase() < b.shopName.toLowerCase()) return -1;
        if (a.shopName.toLowerCase() > b.shopName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const deleteItem = async (id) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: { id: id, status: "deleted" },
      });
      message.error("Vendor Deleted");
      getItemsByOutlet(outletShopId);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by name"
            name="search by name"
            variant="outlined"
            size="small"
            style={{ width: "65%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          {allItems.length > 0 && (
            <Button type="primary">
              <CSVLink
                data={allItems}
                headers={headers}
                filename={test.test == "vendor" ? "Vendor.csv" : "Dealers.csv"}
              >
                Export to CSV
              </CSVLink>
            </Button>
          )}
          <Button
            type="primary"
            onClick={() => navigate(`../AddVendor/addNewVendor/${test.test}`)}
          >
            <PlusOutlined />{" "}
            {test.test == "vendor" ? "Add New Vendor" : "Add New Dealer"}
          </Button>
        </div>
        <p>
          {term == ""
            ? allItems.length
            : allItems.filter(
                (item) =>
                  item.shopName
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1 ||
                  item.phoneNumber
                    .toString()
                    .toLowerCase()
                    .indexOf(term.toLowerCase()) > -1
              ).length}

          {test.test == "vendor" ? " Vendor(s)" : " Dealer(s)"}
        </p>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.shopName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.phoneNumber
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 420 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
