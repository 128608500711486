import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, message, Card, Select, Modal } from "antd";
import { TextField } from "@mui/material";

function AddVendor(props) {
  const navigate = useNavigate();
  const [itemName, setItemName] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [itemUOM, setItemUOM] = useState("");
  const [name, setName] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [mchCatList, setMchCatList] = useState([]);
  const [modal, setModal] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const { Option } = Select;

  let test = useParams();

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getOutletDetail(user.attributes["custom:outletShopId"]);
          if (test.test != "addNewRecord") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (props.common.shopType === "root") navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      // console.log(allTodos.data.getItemById)
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setItemName(res.itemName);
        setItemDescription(res.itemDescription);
        setItemUOM(res.itemUOM);
        setName(res.name);
        setTotalAmount(res.totalAmount);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getOutletDetail = async (outletShopId) => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: outletShopId },
      });
      let res = allTodos.data.getItemById;
      setMchCatList(res.mchCatList ?? []);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          itemName: itemName,
          itemDescription: itemDescription,
          itemUOM: itemUOM,
          name: name,
          totalAmount: totalAmount,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: randomId,
          addedBy: addedBy,
          type: "Machine",
        },
      });
      message.error("Machine Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          itemName: itemName,
          itemDescription: itemDescription,
          itemUOM: itemUOM,
          name: name,
          totalAmount: totalAmount,
          updatedBy: updatedBy,
        },
      });
      message.error("Machine updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addNewCategory = async () => {
    if (newCategory == "") return alert("Please enter new category");
    let temp = [...mchCatList];
    temp.push(newCategory);
    console.log(temp);
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: outletShopId,
          mchCatList: temp,
        },
      });
      await getOutletDetail(outletShopId);
      setItemDescription(newCategory);
      setModal(false);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (itemName === "") return message.error("Please enter machine name");
    if (itemDescription === "")
      return message.error("Please enter machine type");
    if (itemUOM === "") return message.error("Please enter unit");
    if (name === "") return message.error("Please enter technicaian name");
    if (totalAmount === "") return message.error("Please enter total amount");
    setSpinnerState("true");
    if (test.test === "addNewRecord") addItem();
    else editItem();
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <h1 className="bill-heading">Enter Machine Details</h1>
          </Col>
          <Col style={{ paddingRight: 20 }}>
            <Button
              className="button"
              type="primary"
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {test.test === "addNewRecord"
                ? "Add Machine Details"
                : "Update Machine Details"}
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Card style={{ width: "100%", marginLeft: 20, marginRight: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <TextField
                  label="Machine Name"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={itemName}
                  size="small"
                  onChange={(e) => setItemName(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Select
                  showSearch
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={itemDescription}
                  onChange={(value) => {
                    if (value == "New") setModal(true);
                    else setItemDescription(value);
                  }}
                >
                  <Option value="">Select a type</Option>
                  {mchCatList.map((value) => {
                    return <Option value={value}>{value}</Option>;
                  })}
                  <Option value="New">Add New</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <Select
                  style={{
                    width: "80%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={itemUOM}
                  onChange={(value) => setItemUOM(value)}
                >
                  <Option value="">Select Unit Name</Option>
                  <Option value="Unit A">Unit A</Option>
                  <Option value="Unit B">Unit B</Option>
                </Select>
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  label="Technician Name"
                  variant="outlined"
                  style={{ width: "70%", backgroundColor: "white" }}
                  margin="normal"
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  inputProps={{
                    maxLength: 100,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <TextField
                  label="Total Amount"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={totalAmount}
                  size="small"
                  onChange={(e) => setTotalAmount(e.target.value)}
                  inputProps={{
                    maxLength: 10,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
          </Card>
        </Row>
      </div>
      <Modal
        visible={modal}
        style={{ width: "100%" }}
        onCancel={() => setModal(false)}
        footer={null}
      >
        <div className="bill">
          <div className="bill-container">
            <TextField
              label="Enter new category"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              inputProps={{
                maxLength: 500,
              }}
              size="small"
              style={{ width: "90%" }}
              autoComplete="off"
            />
          </div>
          <div className="fieldStyle">
            <Button
              className="button"
              type="primary"
              onClick={() => addNewCategory()}
            >
              Add
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
