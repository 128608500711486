import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth, API } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, Modal, message, Divider } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import { getItemsByTypeFunc, getItemIdByTypeFunc } from "./functionCall";

function ListComponents(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [term, setTerm] = useState("");
  const [modal, setModal] = useState("");
  const [inventoryByOutlet, setInventoryByOutlet] = useState({});

  const Columns = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      width: "20%",
      ellipsis: true,
    },
    {
      title: "Formula",
      dataIndex: "formula",
      key: "formula",
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Purchase Price",
      dataIndex: "masterPP",
      key: "masterPP",
      width: "15%",
      ellipsis: true,
    },
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      width: "10%",
      ellipsis: true,
    },
    ...(props.common.shopType == "master"
      ? [
          {
            title: "Inventory",
            dataIndex: "id",
            key: "id",
            width: "10%",
            ellipsis: true,
            render: (id) => (
              <a
                onClick={() => {
                  getItemIdByType(id);
                }}
              >
                Inventory
              </a>
            ),
          },
        ]
      : []),
    {
      title: "Edit",
      dataIndex: "id",
      key: "id",
      width: "10%",
      ellipsis: true,
      render: (id) => (
        <a onClick={() => navigate(`../AddComponents/${id}`)}>Edit</a>
      ),
    },
    {
      title: "Delete",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: "10%",
      render: (id) => (
        <a onClick={() => deleteItem(id)} style={{ color: "red" }}>
          Delete
        </a>
      ),
    },
  ];

  const columnsInventory = [
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      ellipsis: true,
      render: (location, record) => (
        <a
          style={{
            color:
              record.remainingQuantity && record.lowStockValue
                ? record.remainingQuantity < record.lowStockValue
                  ? "red"
                  : "black"
                : "black",
          }}
        >
          {location}
        </a>
      ),
    },
    {
      title: "Raw",
      dataIndex: "rawMaterial",
      key: "rawMaterial",
      ellipsis: true,
      render: (rawMaterial) => (!isNaN(rawMaterial) ? rawMaterial : "NA"),
    },
    {
      title: "Incoming",
      dataIndex: "incomingQuantity",
      key: "incomingQuantity",
      ellipsis: true,
      render: (incomingQuantity) =>
        !isNaN(incomingQuantity) ? incomingQuantity : "NA",
    },
    {
      title: "Sold",
      dataIndex: "soldQuantity",
      key: "soldQuantity",
      ellipsis: true,
      render: (soldQuantity) => (!isNaN(soldQuantity) ? soldQuantity : "NA"),
    },
    {
      title: "Remaining",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      ellipsis: true,
      render: (remainingQuantity) =>
        !isNaN(remainingQuantity) ? remainingQuantity : "NA",
    },
    {
      title: "Low Stock",
      dataIndex: "lowStockValue",
      key: "lowStockValue",
      ellipsis: true,
      render: (lowStockValue) => (!isNaN(lowStockValue) ? lowStockValue : "NA"),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();

    if (!checkAccess("Materials")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  // const getAllOutlets = async (shopId) => {};

  const deleteItem = async (id) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: { id: id, status: "deleted" },
      });
      message.error("Material Deleted");
      getItemsByType(shopId);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemIdByType = async (id) => {
    let temp = {};

    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: shopId },
      });
      let res1 = allTodos.data.getItemById;
      temp[res1.id] = {};
      temp[res1.id].id = res1.id;
      temp[res1.id].location = res1.location;
    } catch (error) {
      console.log(JSON.stringify(error));
    }

    let allItems = await getItemsByTypeFunc("Outlet", shopId);
    let res2 = allItems.sort(function (a, b) {
      if (a.location.toLowerCase() < b.location.toLowerCase()) return -1;
      if (a.location.toLowerCase() > b.location.toLowerCase()) return 1;
      return 0;
    });
    res2.map((item) => {
      temp[item.id] = {};
      temp[item.id].id = item.id;
      temp[item.id].location = item.location;
    });

    let allItems1 = await getItemIdByTypeFunc("Inventory", id);
    allItems1.map((item) => {
      if (temp[item.outletShopId] != undefined) {
        temp[item.outletShopId].rawMaterial = item.rawMaterial;
        temp[item.outletShopId].incomingQuantity = item.incomingQuantity;
        temp[item.outletShopId].remainingQuantity = item.remainingQuantity;
        temp[item.outletShopId].soldQuantity = item.soldQuantity;
        temp[item.outletShopId].lowStockValue = item.lowStockValue;
      }
    });
    setInventoryByOutlet(temp);
    setModal(true);
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1500px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 20,
            }}
          >
            <TextField
              id="outlined-basic"
              label="Search by name"
              name="search by name"
              variant="outlined"
              size="small"
              style={{ width: "80%", backgroundColor: "white" }}
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
            {props.common.shopType == "master" && (
              <Button type="primary">
                <Link to="../AddComponents/addNewComponent">
                  <PlusOutlined /> Add New Material
                </Link>
              </Button>
            )}
          </div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Card style={{ width: "100%" }}>
                <div>
                  <Table
                    columns={Columns}
                    dataSource={
                      term == ""
                        ? allItems
                        : allItems.filter(
                            (item) =>
                              item.itemName
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1 ||
                              item.itemDescription
                                .toString()
                                .toLowerCase()
                                .indexOf(term.toLowerCase()) > -1
                          )
                    }
                    scroll={{ y: 520 }}
                    pagination={{ pageSize: 100 }}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        style={customStylesAddProducts}
        visible={modal}
        width="50%"
        onCancel={() => setModal(false)}
        footer={null}
        title={<h2 style={{ fontSize: "20px" }}>Inventory by Location</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />

        <div className="bill-container">
          <Row gutter={[16, 16]}>
            <Col xs={24} md={16} lg={18} offset={2}>
              <Table
                dataSource={Object.values(inventoryByOutlet)}
                columns={columnsInventory}
                pagination={{ pageSize: 100 }}
              />
            </Col>
            <Col
              xs={24}
              md={16}
              lg={2}
              offset={20}
              style={{ paddingTop: "2%" }}
            >
              <Button type="primary" block onClick={() => setModal(false)}>
                Ok
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListComponents);
