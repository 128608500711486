import {
  SET_JSON,
  SET_USERDATA,
  MENU_KEY,
  SET_SHOPDETAILS,
  SET_OUTLETDETAILS,
  SET_VARIABLE,
} from "../action";
import crm from "../../images/crm.jpeg";

const initialState = {
  email: "",
  shopName: "",
  shopType: "",
  role: "",
  randomId: "",
  shopId: "",
  outletShopId: "",
  roleArray: [],
  groupArray: [],
  UOMArray: [],
  calBasedOn: {},
  menuKey: "1",
  formulaList: {},
  tagArray: [],
  currencyArray: {},
  symbol: "",
  location: "",
  itemImages: "",
  GSTNumber: "",
  outletType: "",
  colorArray: [],
  meshColorArray: [],
  leadSourceList: [],
  productTags: [],
  prodStatusArray: [],
  tcktStatusArray: [],
  priorityArray: [],
  holidayList: [],
  profileNames: [],
  formulaList: [],
  outletCategoryArray: [],
  indianStatesAndUTs: [],
  purchaseCatArray: [],
  dealerCatArray: [],
  bankList: [],
  term: "",
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case SET_USERDATA:
      return {
        ...state,
        email: action.payload.email,
        shopName: action.payload.shopName,
        shopType: action.payload.shopType,
        role: action.payload.role,
        randomId: action.payload.randomId,
        shopId: action.payload.shopId,
        outletShopId: action.payload.outletShopId,
        term: action.payload.term,
      };
    case SET_JSON:
      return {
        ...state,
        roleArray: action.payload.roleArray,
        groupArray: action.payload.groupArray,
        UOMArray: action.payload.UOMArray,
        calBasedOn: action.payload.calBasedOn,
        tagArray: action.payload.tagArray,
        currencyArray: action.payload.currencyArray,
        colorArray: action.payload.colorArray,
        meshColorArray: action.payload.meshColorArray,
        leadSourceList: action.payload.leadSourceList,
        productTags: action.payload.productTags,
        prodStatusArray: action.payload.prodStatusArray,
        tcktStatusArray: action.payload.tcktStatusArray,
        priorityArray: action.payload.priorityArray,
        profileNames: action.payload.profileNames,
        formulaList: action.payload.formulaList,
        outletCategoryArray: action.payload.outletCategoryArray,
        indianStatesAndUTs: action.payload.indianStatesAndUTs,
        purchaseCatArray: action.payload.purchaseCatArray,
        dealerCatArray: action.payload.dealerCatArray,
        bankList: action.payload.bankList,
        menuKey: "1",
      };
    case MENU_KEY:
      return {
        ...state,
        menuKey: action.payload.menuKey,
      };
    case SET_SHOPDETAILS:
      return {
        ...state,
        formulaList: JSON.parse(action.payload.formulaList),
        symbol: action.payload.symbol,
        holidayList: action.payload.holidayList,
      };
    case SET_OUTLETDETAILS:
      return {
        ...state,
        itemImages: action.payload.itemImages
          ? action.payload.itemImages[0]
          : crm,
        GSTNumber: action.payload.GSTNumber,
        outletType: action.payload.outletType,
        location: action.payload.location,
      };
    case SET_VARIABLE:
      return { ...state, [action.payload.key]: action.payload.value };
    default:
      return state;
  }
}
