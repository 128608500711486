import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import { Row, Col, Button, Input, Table, message, Card } from "antd";
import { checkAccess } from "./AuthorizationService";
import { getAllItemsPagination10 } from "./functionCall";

function AddRO(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  let test = useParams();

  const columns = [
    {
      title: "Item Code",
      dataIndex: "itemCode",
      key: "itemCode",
      ellipsis: true,
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Item Size",
      dataIndex: "itemSize",
      key: "itemSize",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
    },
    {
      title: "Received Quantity",
      dataIndex: "receivedQty",
      key: "receivedQty",
      ellipsis: true,
      render: (receivedQty, record) => (
        <Input
          value={receivedQty}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "receivedQty", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "receivedQty", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("PurchaseOrder")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setVendorId(res.vendorId);
        setVendorName(res.vendorName);
        setComponentList(JSON.parse(res.componentList));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("ReceivedOrder", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "RO-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "RO-" + year + "-" + tempCounter;

    try {
      let AddItem = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          vendorId: vendorId,
          vendorName: vendorName,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          type: "ReceivedOrder",
          PO_ID: test.test,
        },
      });

      updatePO_ID(AddItem.data.addItem.id);
      message.error("Received Order Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const updatePO_ID = async (id) => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          updatedBy: updatedBy,
          PO_ID: id,
        },
      });
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          updatedBy: updatedBy,
          componentList: JSON.stringify(componentList),
        },
      });
      message.error("Received Order updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.receivedQty == 0 || value.receivedQty == undefined) {
        flag = true;
        return message.error("Enter received quantity for " + value.itemName);
      }
    });

    if (flag) return;

    setSpinnerState("true");

    if (test.from == "PO") addItem();
    else editItem();
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    if (
      value == "receivedQty" &&
      parseFloat(data) > parseFloat(temp[key].quantity)
    )
      return alert("Ordered Quantity is " + temp[key].quantity);

    temp[key][value] = data;
    setComponentList(temp);
  };

  // const downloadPDF = async () => {
  //   await pdf(
  //     <GeneratePO item={itemDetails} vendorDetails={allVendors[vendorId]} />
  //   )
  //     .toBlob()
  //     .then(async (blob) => {
  //       saveAs(blob, `RO ${itemDetails.randomId}.pdf`);
  //     });
  // };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 40,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            marginLeft: -20,
            display: "flex",
            justifyContent: "space-between",
            margin: 10,
          }}
        >
          <h1 className="bill-heading">Received Order Details</h1>
          {/* {test.from == "RO" && (
            <Button
              className="button"
              type="primary"
              onClick={() => downloadPDF()}
              style={{ width: 150 }}
            >
              Download PDF
            </Button>
          )} */}
        </div>
        <Row gutter={[16, 16]}>
          <Card style={{ width: "100%" }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={10} offset={1}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Vendor:
                  </label>
                  <label>{vendorName}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Row>
        <Row gutter={[16, 16]}>
          {Object.entries(componentList).length > 0 && (
            <Col xs={24} sm={10} style={{ paddingTop: 20 }}>
              <span>{Object.entries(componentList).length} item(s) added</span>
            </Col>
          )}
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={24} lg={24}>
            <Table
              dataSource={Object.values(componentList)}
              columns={columns}
              className="my-table"
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12} lg={8}>
            <Button
              className="button"
              type="primary"
              size="large"
              disabled={spinnerState}
              onClick={() => checkInput()}
              style={{ marginTop: 20 }}
            >
              {test.from == "PO"
                ? "Add Received Order"
                : "Update Received Order"}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRO);
