import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  Table,
  message,
  Divider,
  Select,
} from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { CloseOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { checkAccess } from "./AuthorizationService";
import {
  getItemsByTypeFunc,
  getAllItemsPagination10,
  getItemsByOutletPagination,
} from "./functionCall";
import { v4 as uuidv4 } from "uuid";
const { Option } = Select;

function AddProductGroups(props) {
  const navigate = useNavigate();
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [notes, setNotes] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  let test = useParams();

  const columns = [
    {
      title: "Material Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Remaining Quantity",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      ellipsis: true,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record) => (
        <Input
          value={quantity}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "quantity", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "quantity", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
      render: (color, record) => (
        <Select
          style={{ width: "90%" }}
          onChange={(value) => {
            addValue(record.key, "color", value);
          }}
          value={color}
        >
          {props.common.colorArray
            .concat(props.common.meshColorArray)
            .map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
        </Select>
      ),
    },
    ...(test.test === "addNewST"
      ? [
          {
            title: "Add",
            dataIndex: "key",
            key: "key",
            ellipsis: true,
            render: (key) => (
              <Button
                type="primary"
                danger
                onClick={() => removeComponent(key)}
              >
                Remove
              </Button>
            ),
          },
        ]
      : []),
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Price",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Remaining Quantity",
      dataIndex: "remainingQuantity",
      key: "remainingQuantity",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{
            width: Object.keys(componentList).includes(id) ? "" : "55%",
          }}
          danger={Object.keys(componentList).includes(id) ? true : ""}
        >
          {Object.keys(componentList).includes(id) ? "" : <PlusOutlined />}
          {Object.keys(componentList).includes(id) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          if (test.test != "addNewST") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };
    getUser();
    if (!checkAccess("StockTransfer")) navigate("/homePage");
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      // console.log(allTodos.data.getItemById)
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setComponentList(JSON.parse(res.componentList));
        setNotes(res.notes);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);

    let allIvnt = await getItemsByOutletPagination("Inventory", "live", shopId);
    if (allItems.length > 0) {
      allIvnt.map((item) => {
        allItems.map((value) => {
          if (value.id == item.itemId) {
            value.remainingQuantity = item.remainingQuantity;
          }
        });
      });
    }

    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const addItem = async () => {
    const today = new Date();
    const year = today.getFullYear();
    let lastNumber = undefined;
    let allItems = await getAllItemsPagination10("StockTransfer", outletShopId);
    if (allItems.length > 0) {
      lastNumber = allItems[0].randomId;
    } else lastNumber = "ST-" + year + "-0";

    let tempCounter =
      lastNumber.slice(3, 7) == year ? parseInt(lastNumber.slice(8)) + 1 : 1;
    let tempId = "ST-" + year + "-" + tempCounter;
    try {
      let stockTransfer = await API.graphql({
        query: mutations.addItem,
        variables: {
          randomId: tempId,
          componentList: JSON.stringify(componentList),
          shopId: shopId,
          outletShopId: outletShopId,
          addedBy: addedBy,
          notes: notes,
          type: "StockTransfer",
          stockReceived: "false",
          time: moment(new Date()).format("HH:mm:ss"),
        },
      });
      updateSTInventory(stockTransfer.data.addItem.id);
    } catch (error) {
      console.log(JSON.stringify(error));
      message.error(JSON.stringify(error));
    }
  };

  const updateSTInventory = async (id) => {
    try {
      await API.graphql({
        query: mutations.updateSTInventory,
        variables: {
          updatedBy: updatedBy,
          id: id,
        },
      });
      message.error("Stock Transfer Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (Object.keys(componentList).length == 0)
      return message.error("Please add items to the stock transfer");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
    });

    if (flag) return;
    setSpinnerState("true");
    addItem();
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    let key = uuidv4();
    tempList[key] = {};
    tempList[key].id = item.id;
    tempList[key].key = key;
    tempList[key].itemName = item.itemName;
    tempList[key].remainingQuantity = item.remainingQuantity;
    tempList[key].quantity = 0;
    tempList[key].color = "Other";
    setComponentList(tempList);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    if (value == "quantity" && data > temp[key].remainingQuantity)
      return alert("Available quantity is " + temp[key].remainingQuantity);

    temp[key][value] = data;
    setComponentList(temp);
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <h1 className="bill-heading">Stock Transfer Details</h1>
        <Row>
          <Col xs={24} lg={20}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "8px 0",
                  }}
                >
                  <label
                    style={{
                      width: "120px",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    Notes
                  </label>
                  <Input
                    style={{ flex: 1 }}
                    placeholder="Enter notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    maxLength={500}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={23} lg={19} offset={1}>
                <Table
                  dataSource={Object.values(componentList)}
                  columns={columns}
                  className="my-table"
                />
              </Col>
            </Row>
            {test.test === "addNewST" && (
              <Row>
                <Col xs={12} lg={5} style={{ padding: 10 }} offset={4}>
                  <div>
                    <Button
                      className="button"
                      type="primary"
                      disabled={spinnerState}
                      onClick={() => setModalAddComponents(true)}
                      style={{ margin: 10 }}
                    >
                      <PlusOutlined /> Add Items
                    </Button>
                  </div>
                </Col>
                <Col xs={12} lg={5} style={{ padding: 10 }} offset={2}>
                  <div>
                    <Button
                      className="button"
                      type="primary"
                      disabled={spinnerState}
                      onClick={() => checkInput()}
                      style={{ margin: 10 }}
                    >
                      Add Stock Transfer
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        visible={modalAddComponents}
        width={"70%"}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        {/* <h1 className="bill-heading">Select Components</h1> */}
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term === ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={8} offset={20}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddProductGroups);
