import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../styles/listProducts.css";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { Row, Col, Card, Table, Button, Switch } from "antd";
import { TextField, InputAdornment } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  getItemsByShopTypeFunc,
  getItemsByShopTypeFunc100,
} from "./functionCall";
import { checkAccess } from "./AuthorizationService";

function ListPO(props) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = useState([]);
  const [term, setTerm] = useState("");
  const [showAll, setShowAll] = useState(false);
  const [shopId, setShopId] = useState("");

  const Columns = [
    {
      title: "PO Name",
      dataIndex: "randomId",
      key: "randomId",
      ellipsis: true,
    },
    {
      title: "Added Date",
      dataIndex: "addedDate",
      key: "addedDate",
      ellipsis: true,
      render: (addedDate) => moment(addedDate).format("DD MMM YY"),
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "View",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id) => <a onClick={() => navigate(`../AddPO/${id}`)}>View</a>,
    },
    {
      title: "Received",
      dataIndex: "PO_ID",
      key: "PO_ID",
      ellipsis: true,
      render: (PO_ID, record) => (
        <a
          onClick={() =>
            PO_ID
              ? navigate(`../AddRO/${PO_ID}/RO`)
              : navigate(`../AddRO/${record.id}/PO`)
          }
        >
          {PO_ID ? "View RO" : "Add RO"}
        </a>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setShopId(user.attributes["custom:shopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
        })
        .catch((err) => {
          console.log("err " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    getUser();
    if (!checkAccess("PurchaseOrder")) navigate("/homePage");
  }, []);

  const getItemsByType = async (shopId) => {
    let allItems = [];
    if (showAll)
      allItems = await getItemsByShopTypeFunc("PurchaseOrder", shopId);
    else allItems = await getItemsByShopTypeFunc100("PurchaseOrder", shopId);
    setAllItems(
      allItems.sort(
        (a, b) =>
          moment(b.addedDate + " " + b.time, "YYYY-MM-DD  HH:mm:ss") -
          moment(a.addedDate + " " + a.time, "YYYY-MM-DD  HH:mm:ss")
      )
    );
  };

  useEffect(() => {
    const getData = async () => {
      setAllItems([]);
      await getItemsByType(shopId);
    };
    if (shopId != "") getData();
  }, [showAll]);

  const toggleButton = () => {
    setShowAll((prevState) => !prevState);
  };

  return (
    <div>
      <div
        style={{
          padding: 10,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <TextField
            id="outlined-basic"
            label="Search by id"
            name="search by id"
            variant="outlined"
            size="small"
            style={{ width: "60%", backgroundColor: "white" }}
            value={term}
            onChange={(e) => setTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
            }}
            autoComplete="off"
          />
          <label
            style={{
              fontWeight: "bold",
              marginLeft: 10,
              marginRight: -100,
              width: 250,
            }}
          >
            {showAll ? "Show last 100" : "Show all PO"}
          </label>
          <Switch
            style={{ marginRight: 10, width: 50 }}
            checked={showAll}
            onChange={toggleButton}
          />
          {props.common.role != "Store" &&
            props.common.role != "ProdManager" && (
              <Button type="primary">
                <Link to="../ListVendor/vendor">List Vendors</Link>
              </Button>
            )}
          <Button type="primary">
            <Link to="../AddPO/addNewPO">New Purchase Order</Link>
          </Button>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Card style={{ width: "100%" }}>
              <div>
                <Table
                  columns={Columns}
                  dataSource={
                    term == ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.randomId
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1 ||
                            item.vendorName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  scroll={{ x: "50vw", y: 520 }}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};
function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(ListPO);
