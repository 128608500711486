import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "../styles/addProducts.css";
import { Auth, API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  message,
  Card,
  Select,
  Modal,
  Input,
  Table,
  Divider,
} from "antd";
import { getItemsByTypeFunc } from "./functionCall";
import { CloseOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { TextField, InputAdornment } from "@mui/material";

function AddVendor(props) {
  const navigate = useNavigate();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [status, setStatus] = useState("");
  const [shopId, setShopId] = useState("");
  const [outletShopId, setOutletShopId] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [updatedBy, setupdatedBy] = useState("");
  const [spinnerState, setSpinnerState] = useState(false);
  const [source, setSource] = useState("");
  const [destination, setDestination] = useState("");
  const [componentList, setComponentList] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [modalAddComponents, setModalAddComponents] = useState(false);
  const [term, setTerm] = useState("");
  const { Option } = Select;

  let test = useParams();

  const columns = [
    {
      title: "Material Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      ellipsis: true,
      render: (color, record) => (
        <Select
          style={{ width: "90%" }}
          onChange={(value) => {
            addValue(record.key, "color", value);
          }}
          value={color}
        >
          {props.common.colorArray
            .concat(props.common.meshColorArray)
            .map((value) => (
              <Option key={value} value={value}>
                {value}
              </Option>
            ))}
        </Select>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      ellipsis: true,
      render: (quantity, record) => (
        <Input
          value={quantity}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "quantity", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "quantity", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Received Quantity",
      dataIndex: "receivedQty",
      key: "receivedQty",
      ellipsis: true,
      render: (receivedQty, record) => (
        <Input
          value={receivedQty}
          onChange={(e) => {
            if (e.target.value == ".") {
              addValue(record.key, "receivedQty", e.target.value);
            } else if (isNaN(e.target.value))
              message.error("Please enter valid value");
            else addValue(record.key, "receivedQty", e.target.value);
          }}
          maxLength={6}
        />
      ),
    },
    {
      title: "Add",
      dataIndex: "key",
      key: "key",
      ellipsis: true,
      render: (key) => (
        <Button type="primary" danger onClick={() => removeComponent(key)}>
          Remove
        </Button>
      ),
    },
  ];

  const columnsListComponents = [
    {
      title: "Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },
    {
      title: "Description",
      dataIndex: "itemDescription",
      key: "itemDescription",
      ellipsis: true,
    },
    ...(props.common.role != "Store" && props.common.role != "ProdManager"
      ? [
          {
            title: "Price",
            dataIndex: "masterPP",
            key: "masterPP",
            width: "10%",
            ellipsis: true,
          },
        ]
      : []),
    {
      title: "UOM",
      dataIndex: "itemUOM",
      key: "itemUOM",
      ellipsis: true,
    },
    {
      title: "Add",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      render: (id, record) => (
        <Button
          type="primary"
          onClick={() => addComponent(record)}
          style={{
            width: Object.keys(componentList).includes(id) ? "" : "55%",
          }}
          danger={Object.keys(componentList).includes(id) ? true : ""}
        >
          {Object.keys(componentList).includes(id) ? "" : <PlusOutlined />}
          {Object.keys(componentList).includes(id) ? "Remove" : "Add"}
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const getUser = async () => {
      await Auth.currentAuthenticatedUser({
        bypassCache: false,
      })
        .then(async (user) => {
          setAddedBy(user.attributes.email);
          setupdatedBy(user.attributes.email);
          setShopId(user.attributes["custom:shopId"]);
          setOutletShopId(user.attributes["custom:outletShopId"]);
          await getItemsByType(user.attributes["custom:shopId"]);
          if (test.test != "addNewRecord") getItemById();
        })
        .catch((err) => {
          console.log("currentAuthenticatedUser " + JSON.stringify(err));
          if (err == "The user is not authenticated") navigate("/");
        });
    };

    if (props.common.shopType === "root") navigate("/homePage");
    getUser();
  }, []);

  const getItemById = async () => {
    try {
      const allTodos = await API.graphql({
        query: queries.getItemById,
        variables: { id: test.test },
      });
      let res = allTodos.data.getItemById;
      if (res == null) navigate(-1);
      else {
        setFrom(res.from);
        setTo(res.to);
        setStatus(res.status);
        setSource(res.source);
        setDestination(res.destination);
        setComponentList(JSON.parse(res.componentList));
      }
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getItemsByType = async (shopId) => {
    let allItems = await getItemsByTypeFunc("Component", shopId);
    setAllItems(
      allItems.sort(function (a, b) {
        if (a.itemName.toLowerCase() < b.itemName.toLowerCase()) return -1;
        if (a.itemName.toLowerCase() > b.itemName.toLowerCase()) return 1;
        return 0;
      })
    );
  };

  const addItem = async () => {
    let randomId = Math.floor(Math.random() * 10000000000) + 1;
    try {
      await API.graphql({
        query: mutations.addItem,
        variables: {
          from: from,
          to: to,
          shopId: shopId,
          outletShopId: outletShopId,
          randomId: randomId,
          addedBy: addedBy,
          type: "MaterialTransfer",
          source: source,
          componentList: JSON.stringify(componentList),
        },
      });
      message.error("Material Transfer Added");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const editItem = async () => {
    try {
      await API.graphql({
        query: mutations.editItem,
        variables: {
          id: test.test,
          from: from,
          to: to,
          status: status,
          updatedBy: updatedBy,
          destination: destination,
          componentList: JSON.stringify(componentList),
        },
      });
      message.error("Material Transfer updated");
      navigate(-1);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const checkInput = async () => {
    if (from === "") return message.error("Please enter from");
    if (to === "") return message.error("Please enter to");
    if (from === to) return message.error("From and To cannot be same");
    if (source === "") return message.error("Please enter source name");
    if (destination === "") return message.error("Please enter Destination");

    if (Object.keys(componentList).length == 0)
      return message.error("Please add items to the material transfer");

    let flag = false;
    Object.entries(componentList).map(([key, value]) => {
      if (value.quantity == 0 || value.receivedQty == 0) {
        flag = true;
        return message.error("Enter quantity for " + value.itemName);
      }
    });

    if (flag) return;

    setSpinnerState("true");
    if (test.test === "addNewRecord") addItem();
    else editItem();
  };

  const addComponent = (item) => {
    let tempList = { ...componentList };
    let key = uuidv4();
    tempList[key] = {};
    tempList[key].id = item.id;
    tempList[key].key = key;
    tempList[key].itemName = item.itemName;
    tempList[key].quantity = 0;
    tempList[key].color = "Other";
    tempList[key].receivedQty = 0;
    setComponentList(tempList);
  };

  const removeComponent = (key) => {
    let tempList = { ...componentList };
    if (Object.keys(tempList).includes(key)) {
      delete tempList[key];
    }
    setComponentList(tempList);
  };

  const addValue = (key, value, data) => {
    let temp = { ...componentList };
    if (
      value == "receivedQty" &&
      parseFloat(data) > parseFloat(temp[key].quantity)
    )
      return alert("Received Quantity is " + temp[key].quantity);

    temp[key][value] = data;
    setComponentList(temp);
  };

  const customStylesAddProducts = {
    content: {
      top: "50%",
      width: "1100px",
      height: "600px",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          padding: 20,
          backgroundColor: "#E2E2E2",
          width: "100%",
          borderRadius: 10,
        }}
      >
        <Row justify="space-between" align="middle">
          <Col>
            <h1 className="bill-heading">Enter Material Transfer Details</h1>
          </Col>
          <Col>
            <Button
              className="button"
              type="primary"
              disabled={spinnerState}
              onClick={() => setModalAddComponents(true)}
              style={{ margin: 10 }}
            >
              <PlusOutlined /> Add Items
            </Button>
          </Col>
          <Col style={{ paddingRight: 20 }}>
            <Button
              className="button"
              type="primary"
              disabled={spinnerState}
              onClick={() => checkInput()}
            >
              {test.test === "addNewRecord"
                ? "Add Material Transfer Details"
                : "Update Material Transfer Details"}
            </Button>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Card style={{ width: "100%", marginLeft: 20, marginRight: 20 }}>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Select
                  style={{
                    width: "80%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={from}
                  onChange={(value) => setFrom(value)}
                >
                  <Option value="">Select From</Option>
                  <Option value="Unit A">Unit A</Option>
                  <Option value="Unit B">Unit B</Option>
                </Select>
              </Col>
              <Col xs={24} sm={12}>
                <Select
                  style={{
                    width: "80%",
                    backgroundColor: "white",
                    height: 40,
                    marginTop: 15,
                  }}
                  value={to}
                  onChange={(value) => setTo(value)}
                >
                  <Option value="">Select To</Option>
                  <Option value="Unit A">Unit A</Option>
                  <Option value="Unit B">Unit B</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <TextField
                  label="Source Name"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={source}
                  size="small"
                  onChange={(e) => setSource(e.target.value)}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  autoComplete="off"
                />
              </Col>
              <Col xs={24} sm={12}>
                <TextField
                  label="Destination Name"
                  variant="outlined"
                  style={{ width: "80%", backgroundColor: "white" }}
                  margin="normal"
                  value={destination}
                  size="small"
                  onChange={(e) => setDestination(e.target.value)}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  autoComplete="off"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              {test.test != "addNewRecord" && (
                <Col xs={24} sm={12}>
                  <Select
                    style={{
                      width: "80%",
                      backgroundColor: "white",
                      height: 40,
                      marginTop: 15,
                    }}
                    value={status}
                    onChange={(value) => setStatus(value)}
                  >
                    <Option value="">Select a status</Option>
                    <Option value="live">New</Option>
                    <Option value="Dispached">Dispached</Option>
                    <Option value="Received">Received</Option>
                  </Select>
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={24} md={23} lg={19} offset={1}>
                <Table
                  dataSource={Object.values(componentList)}
                  columns={columns}
                  className="my-table"
                />
              </Col>
            </Row>
          </Card>
        </Row>
      </div>
      <Modal
        visible={modalAddComponents}
        width={"70%"}
        onCancel={() => setModalAddComponents(false)}
        footer={null}
        style={customStylesAddProducts}
        title={<h2 style={{ fontSize: "20px" }}>Select Materials</h2>}
        closeIcon={
          <CloseOutlined
            style={{ fontSize: "24px", margin: 20, marginLeft: -30 }}
          />
        }
      >
        <Divider />
        {/* <h1 className="bill-heading">Select Components</h1> */}
        <div className="bill">
          <div className="bill-container">
            <TextField
              id="outlined-basic"
              label="Search by name"
              variant="outlined"
              size="small"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
              style={{ width: "95%" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlined />
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />

            <Row gutter={[16, 16]}>
              <Col xs={24} md={23}>
                <Table
                  dataSource={
                    term === ""
                      ? allItems
                      : allItems.filter(
                          (item) =>
                            item.itemName
                              .toString()
                              .toLowerCase()
                              .indexOf(term.toLowerCase()) > -1
                        )
                  }
                  columns={columnsListComponents}
                  className="my-table"
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12} md={8} lg={8} offset={20}>
                <div className="discount">
                  <Button
                    type="primary"
                    onClick={() => setModalAddComponents(false)}
                  >
                    Add Item
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
